(function () {
    angular.module('informaApp')
        .component('trendsViewFilter', {
            templateUrl: 'components/trends-view-profile/trends-view-filter/template.html',
            controller: TrendsViewFilterComponent,
            bindings: {
                filter: '<',
                onFilterChanges: '<'
            }
        });

    function TrendsViewFilterComponent(ConstantsSvc, MetricsHelper, MetricsTypes) {
        this.metricsConfig = getMetricsConfiguration(MetricsHelper, MetricsTypes);
        this.currentMetric = this.metricsConfig[0];

        this.yearRangesConfig = getYearRangesConfiguration(ConstantsSvc);
        this.currentYearRange = this.yearRangesConfig.find(x => x.range === ConstantsSvc.trendsView.defaultRange);

        this.$onChanges = (changes) => {
            if (changes.filter && changes.filter.currentValue) {
                this.currentMetric = this.metricsConfig.find(x => x.id === this.filter.metric) || this.currentMetric;
                this.currentYearRange = this.yearRangesConfig.find(x => x.range === this.filter.yearRange) || this.currentYearRange;
            }

            if (changes.onFilterChanges) {
                emitOnFilterChanges.call(this, MetricsHelper);
            }
        };

        this.emitOnChanges = () => {
            emitOnFilterChanges.call(this, MetricsHelper);
        };
    }

    function emitOnFilterChanges() {
        this.onFilterChanges({
            metric: this.currentMetric.id,
            yearRange: this.currentYearRange.range
        });
    }

    function getMetricsConfiguration(MetricsHelper, MetricsTypes) {
        return [
            {id: MetricsTypes.pos, title: MetricsHelper.pos},
            {id: MetricsTypes.loa, title: MetricsHelper.loa},
            {id: MetricsTypes.duration, title: MetricsHelper.duration}
        ];
    }

    function getYearRangesConfiguration(ConstantsSvc) {
        const yearRanges = ConstantsSvc.trendsView.yearRanges;

        return yearRanges.map(range => {
            return {
                range,
                title: ConstantsSvc.trendsView.getYearRangeString(range)
            };
        });
    }
})();
